<template>
    <div>
        {{terminalId}}
        <div ref="term">

        </div>
    </div>
</template>
<script>

    import { Terminal } from 'xterm'
    import { FitAddon } from 'xterm-addon-fit'
    import { WebLinksAddon } from 'xterm-addon-web-links'

    import 'xterm/css/xterm.css'

    export default {
        props: {
            terminalId: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                websocket: null
            }
        },
        mounted() {

            this.websocket = new WebSocket(`${location.origin.replace(/^http/, 'ws')}/api/terminal/v1/terminal/${  this.terminalId  }/shell`)
            this.websocket.binaryType = 'arraybuffer'

            const thisIns = this
            this.websocket.onopen = function() {

                function ab2str(buf) {
                    return String.fromCharCode.apply(null, new Uint8Array(buf))
                }
                
                const terminal = new Terminal(
                    {
                        screenKeys: true,
                        useStyle: true,
                        cursorBlink: true
                    }
                )
                const fitAddon = new FitAddon()
                terminal.loadAddon(new WebLinksAddon())
                terminal.loadAddon(fitAddon)

                terminal.onData(function(data) {
                    thisIns.websocket.send(new TextEncoder().encode(`\x00${  data}`))
                })

                terminal.onResize(function(evt) {
                    thisIns.websocket.send(new TextEncoder().encode(`\x01${  JSON.stringify({cols: evt.cols, rows: evt.rows})}`))
                })

                terminal.open(thisIns.$refs.term)
                fitAddon.fit()

                thisIns.websocket.onmessage = function(evt) {
                    if (evt.data instanceof ArrayBuffer) {
                        terminal.write(ab2str(evt.data))
                    }
                }

                thisIns.websocket.onclose = function() {
                    terminal.write('Session terminated')
                }
            }
        },
        beforeDestroy() {
            this.websocket.close()
        }
    }
</script>